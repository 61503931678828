<template>
    <div class="container-lg">
        <hr>
    </div>
</template>

<script>
    export default {
        name: 'DividerLine',
    }
</script>

<style scoped></style>
