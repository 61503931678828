<template>
    <div class="investors d-flex flex-wrap flex-md-row align-items-center">
        <img
            src="../assets/images/pages/marketing/careers/khosla.png"
            alt="Khosla Ventures"
            class="khosla"
        >
        <img
            src="../assets/images/pages/marketing/careers/scifi.png"
            alt="SciFi VC"
            class="scifi"
        >
        <img
            src="../assets/images/pages/marketing/careers/founders.png"
            alt="Founders Fund"
            class="founders"
        >
        <img
            src="../assets/images/pages/marketing/careers/nyca.png"
            alt="NYCA Capital"
            class="nyca"
        >
        <img
            src="../assets/images/pages/marketing/careers/sequoia.png"
            alt="Sequoia Capital"
            class="sequoia"
        >
        <img
            src="../assets/images/pages/marketing/careers/caffeinated.png"
            alt="Caffeinated Capital"
            class="caffinated"
        >
    </div>
</template>

<script>
    export default {
        name: 'Investors',
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/investors';
</style>
