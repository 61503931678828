<template>
    <marketing-layout>
        <marketing-hero>
            <h1
                class="fw-bold heading-3 text-responsive mb-3"
                v-html="$t('pages.marketing.about.titleHtml')"
            />
            <h2 class="fw-light heading-5 text-responsive mb-0">
                {{ $t('pages.marketing.about.subTitle') }}
            </h2>
        </marketing-hero>

        <divider-line />

        <section-block>
            <div class="container-lg">
                <div class="row">
                    <div class="col-12 col-md-7">
                        <section-header
                            variant="text-primary"
                            class="mb-2"
                            underline
                        >
                            Partners
                        </section-header>
                        <h4>
                            Re-inventing capital.<br>
                            Built for security.
                        </h4>
                        <p>
                            We are partnered with Coastal Community Bank, member FDIC, pursuant to license by Visa USA Inc. You benefit from our innovation, great pricing, and simple convenience with
                            the trust of a traditional FDIC-insured bank and the Visa network.
                        </p>
                    </div>
                    <div class="col-12 col-md-3 offset-md-1 d-flex flex-md-column align-items-start justify-content-md-center align-items-md-center pt-5 pt-md-8">
                        <img
                            src="@/assets/images/global/visa-logo.png"
                            alt="Visa Card"
                            height="28"
                            class="me-5 me-md-0"
                        >
                        <img
                            src="@/assets/images/global/coastal-logo.png"
                            alt="Coastal"
                            height="28"
                            class="mt-md-5 mt-md-0"
                        >
                    </div>
                </div>
            </div>
        </section-block>

        <divider-line />

        <section-block>
            <div class="container-lg">
                <div class="row">
                    <div class="col-12 col-md-5 order-md-1">
                        <section-header
                            variant="text-primary"
                            class="mb-2"
                            underline
                        >
                            Investors
                        </section-header>
                        <h4>Backed by top tier investors</h4>
                        <p>
                            Aven is funded by a number of the largest investors with deep expertise in financial services and technology. These are the same investors that backed companies like
                            Square, Affirm, Stripe and PayPal.
                        </p>
                    </div>
                    <div class="col-12 col-md-7 d-flex flex-column order-md-0 mt-5 mt-md-0">
                        <investors />
                    </div>
                </div>
            </div>
        </section-block>
    </marketing-layout>
</template>

<script>
    import Marketing from '../../layouts/Marketing'
    import SectionBlock from '@/components/SectionBlock'
    import MarketingHero from '@/components/MarketingHero'
    import DividerLine from '@/components/DividerLine'
    import SectionHeader from '@/components/SectionHeader'
    import Investors from '@/components/Investors'

    export default {
        name: 'About',
        metaInfo: {
            title: 'About',
            meta: [{ name: 'description', vmid: 'description', content: 'Our mission is to provide the lowest cost, most convenient, and most transparent access to capital.' }],
        },
        mounted: async function () {
            this.$logEvent('view_about')
        },
        components: {
            Investors,
            'marketing-layout': Marketing,
            SectionBlock,
            MarketingHero,
            DividerLine,
            SectionHeader,
        },
    }
</script>
