import { getPolicyForExperiment } from '@/experiments/getPolicyForExperiment'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { ExperimentName } from '@/experiments/experimentName'
import { getEnabledOverrides } from '@/experiments/getOverrideForExperiment'

export const experimentsMixin = {
    computed: {
        heraclesParameter: function () {
            return getPolicyForExperiment(appSessionStorage.getItem(sessionStorageKey.experimentName) as ExperimentName)
        },
        overrideParameters: function () {
            return getEnabledOverrides()
        },
        ExperimentName: function () {
            return ExperimentName
        },
    },
}
